import * as React from "react"
import { toTruncatedNumber } from "../../../../common/misc-utils"
import InformationOutlineSvg from "../../../components/icons/InformationOutline"
import { DlStyled } from "../styles/EntryBar.styles"

export const ptsAvailDt = "Pts Avail"

export interface IDlItemProps {
  dt: any
  dd: any
  showScoringBreakdown?: (a1?: any, a2?: any) => void
  usesScoringBreakdown?: boolean
  className?: string
  as?: any
}

const DlItem = ({ dt, dd, as, showScoringBreakdown, usesScoringBreakdown = false, ...rest }: IDlItemProps) => {
  const isNumber = typeof dd === "number"
  const adjustedDD = (isNumber && toTruncatedNumber(dd)) || dd
  if (dt === ptsAvailDt && showScoringBreakdown) {
    return (
      <DlStyled {...rest} as={as !== "dl" ? "button" : as} onClick={showScoringBreakdown}>
        <dt>{dt}</dt>
        <dd>
          {adjustedDD}
          <InformationOutlineSvg />
        </dd>
      </DlStyled>
    )
  }
  if (usesScoringBreakdown && showScoringBreakdown && dd !== "-") {
    return (
      <DlStyled {...rest} as={as !== "dl" ? "button" : as} onClick={showScoringBreakdown}>
        <dt>{dt}</dt>
        <dd>
          {adjustedDD}
          <InformationOutlineSvg />
        </dd>
      </DlStyled>
    )
  }
  return (
    <DlStyled {...rest} as={as}>
      <dt>{dt}</dt>
      <dd>{adjustedDD}</dd>
    </DlStyled>
  )
}
export default DlItem
