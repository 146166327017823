import styled from "styled-components/macro"
import { breakpoints, fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"
import React from "react"
import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const stickyHeaderH = 148
const stickyHeaderBannerH = 32
const stickyHeaderCollapsedH = 48
const stickyHeaderCollapsedNoBannerH = stickyHeaderCollapsedH - stickyHeaderBannerH

export const EntryBarStickyWrapper = styled.div`
  background: ${palette.white};
  width: 100%;
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} -${pxToRem(2)} rgba(0, 0, 0, 0.13);
  display: flex;
  flex-flow: column;
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      border-bottom: ${pxToRem(1)} solid ${palette.gray3};
    }
  }
`
export const EntryBarBanner = styled.div``
export const EntryBarStickyContainer = styled.div`
  position: relative;
  border-top: none;
`
export const EntryBarShrinkingArea = styled.div`
  transition: all 0.2s ease-out;
  flex: 1;
`

export const EntryBarModalWrapper = styled.div`
  & .sticky-wrapper {
    border-top-left-radius: ${pxToRem(16)};
    border-top-right-radius: ${pxToRem(16)};
  }
  & .expander {
    position: absolute;
    transition: all 0.2s ease-out;
    left: ${pxToRem(8)};
    top: ${pxToRem(16)};
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
  }
  & .heading {
    width: 100%;
  }
  & .heading:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  & .is-mobile-only--false {
    display: none;
  }
  & .hide-on-scrolling--false {
    display: none;
  }
  /*

  &.is-reversed-toggle--true {
    & .expander {
      left: unset;
      right: 26%;
    }
    & .sticky-container div {
      flex-flow: row-reverse;
    }
  }
  */
`

export const EntryBarWrapper = styled.div`
  width: 100%;
  & .need-picks-el {
    justify-content: flex-start;
    & svg {
      min-height: 4rem;
      max-height: 4rem;
    }
    & > div {
      margin-bottom: 1rem;
    }
  }
  & .expander {
    position: absolute;
    transition: all 0.2s ease-out;
    left: ${pxToRem(8)};
    top: ${pxToRem(16)};
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    transform: rotate(90deg);
  }
  & .prescroll {
    padding-bottom: ${pxToRem(8)};
  }
  & .actions {
    & svg {
      position: absolute;
    }
  }
  & .children-inner {
    overflow: auto;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-overflow-scrolling: touch;
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      height: 100%;
      display: flex;
      flex-flow: column;
      background: ${palette.white};
      border: ${pxToRem(1)} solid ${palette.gray3};
      border-top: none;
    }
    & .actions {
      display: none;
    }
    & hr {
      display: none;
    }
    & .expander {
      display: none;
    }
    & .children {
      flex: 1 1 100%;
      position: relative;
    }
    & .children-inner {
      position: absolute;
    }
    & .hide-on-scrolling--false {
      display: none;
    }
    & .is-mobile-only--true {
      display: none;
    }
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) and (max-height: 800px) {
    & .children {
      border-bottom: 1px solid ${palette.gray3};
    }
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & .children {
      display: none;
      pointer-events: none;
    }
    & .placeholder {
      position: relative;
      height: ${pxToRem(stickyHeaderH)};
    }
    &.is-always-stuck--true .placeholder {
      height: ${pxToRem(stickyHeaderCollapsedH)};
    }
    &.is-always-stuck--true .sticky-wrapper {
      height: ${pxToRem(stickyHeaderCollapsedH)} !important;
    }
    &.has-banner--false .placeholder {
      height: ${pxToRem(stickyHeaderCollapsedNoBannerH)};
    }
    &.has-banner--false .sticky-wrapper {
      height: ${pxToRem(stickyHeaderCollapsedNoBannerH)} !important;
    }
    &.has-banner--false .banner {
      display: none;
    }
    & .sticky-wrapper {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: ${pxToRem(stickyHeaderH)};
      overflow: hidden;
      transition: height 0.2s ease-out, transform 0.2s ease-out;
      z-index: ${zIndexes.subModal1};
    }
    .is-cbs-app-webview--false &.is-ios--true .children {
      top: ${pxToRem(stickyHeaderCollapsedH + layout.siteNavH)};
      bottom: ${pxToRem(layout.mobileBottomAdH)};
    }
    &.is-ios--false {
    }
    &.is-ios--true .sticky-wrapper {
      pointer-events: all;
    }
    &.is-stuck--false.is-open--false {
      & .expander {
        opacity: 0;
        transform: rotate(90deg), translateY(-${pxToRem(16)});
      }
    }
    &.is-stuck--true {
      & .actions {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        & button {
          color: transparent;
        }
      }
      & .heading {
        padding-left: ${pxToRem(16)};
      }
    }
    &.is-stuck--true.is-open--false {
      & .sticky-wrapper {
        height: ${pxToRem(stickyHeaderCollapsedH)};
        pointer-events: all;
      }
    }
    &.hide-banner-for-handheld--false.is-stuck--true.is-open--false {
      & .sticky-wrapper {
        height: ${pxToRem(stickyHeaderCollapsedH + stickyHeaderBannerH)};
      }
    }
    &.is-stuck--true {
      & .shrinking-area {
        opacity: 0;
        max-height: 0;
      }
    }
    &.is-stuck--true .hide-on-scrolling--true {
      display: none;
    }
    &.is-stuck--false .hide-on-scrolling--false {
      display: none;
    }
    & .is-mobile-only--false {
      display: none;
    }
    /* parlay (non-sticky top) version: */
    &.is-callout-only--true .sticky-container {
      display: none;
    }
    &.is-callout-only--true .placeholder {
      height: auto;
    }
    &.is-callout-only--true .sticky-wrapper {
      height: auto;
      position: static;
    }
  }
  /* has-pre-lock-promo */
  &.has-pre-lock-promo--true {
    &.is-locked--false .prescroll {
      display: none;
    }
  }
`

export const DlStyled = styled.dl`
  position: relative;
  & dd {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .information-outline-svg {
    width: 0.8em;
    height: 0.8em;
    margin-left: 0.2em;
  }
`

export const ThreeCol = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  text-align: center;
  height: ${pxToRem(48)};
  & svg {
    height: ${pxToRem(20)};
  }
  & > * {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    flex: 1;
  }
  & h4 {
    font-weight: ${fontWeight.bold};
  }
  & h5 {
    font-weight: ${fontWeight.extraBold};
    font-size: ${pxToRem(12)};
  }
  & dt {
    font-family: ${fontFamily.condensed};
    font-size: ${pxToRem(11)};
    padding-bottom: ${pxToRem(6)};
    text-transform: uppercase;
    color: ${palette.gray2};
    font-weight: ${fontWeight.semiBold};
  }
  & dd {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(16)};
  }
`

export const EntryBarTeamStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background-color: ${palette.white};
  border-left: ${pxToRem(4)} solid ${palette.gray3};
  border-bottom: ${pxToRem(1)} solid ${palette.gray3};
  height: ${pxToRem(40)};
  overflow: hidden;
  font-weight: ${fontWeight.semiBold};
  align-items: center;
  background-image: linear-gradient(270deg, rgba(25, 35, 50, 0) 0%, rgba(25, 35, 50, 0.03) 100%);
  & > * {
    margin-left: ${pxToRem(7)};
    white-space: nowrap;
  }
  & .team-img {
    flex: 0 0 auto;
    width: ${pxToRem(24 + 8)};
    height: ${pxToRem(24)};
    padding-right: ${pxToRem(2)};
  }
  & .points {
    flex: 0 0 ${pxToRem(20)};
    text-align: right;
    font-size: ${pxToRem(10)};
    padding-left: ${pxToRem(6)};
  }
  & .rank {
    flex: 0 0 auto;
    width: ${pxToRem(20)};
    text-align: right;
    font-size: ${pxToRem(11)};
    color: ${palette.gray2};
    padding-left: ${pxToRem(2)};
  }
  & .name {
    font-size: 0.88em;
    color: ${palette.default};
  }
  & .at {
    font-size: 0.69em;
    color: ${palette.gray2};
    overflow: hidden;
    letter-spacing: 0.02em;
  }
  & button {
    flex: 0 0 ${pxToRem(42)};
    padding: ${pxToRem(8)};
    height: 100%;
    margin-left: auto;
    & span {
      color: ${palette.gray3};
      font-size: 0.75em;
    }
  }
`

export const WillHillCalloutContainer = styled.div`
  background-color: ${palette.gray6};
  padding-bottom: ${pxToRem(16)};
  & div {
    display: flex;
    flex-flow: row nowrap;
    font-size: 0.8em;
  }
  & section {
    padding: ${pxToRem(16)} ${pxToRem(8)};
  }
  & header {
    font-weight: ${fontWeight.semiBold};
    padding-bottom: ${pxToRem(8)};
  }
  & strong {
    color: ${palette.yellow80};
    font-weight: ${fontWeight.semiBold};
  }
  & button {
    margin: 0 auto;
    background-color: ${palette.yellow60} !important;
    border: none !important;
  }
`

export interface ICalloutBarProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: "red" | "green" | "orange" | "orange2" | "blue" | "gray" | "yellow"

  hideForHandheld?: boolean
  isProminentVersion?: boolean
}
export const CalloutBar = React.memo<ICalloutBarProps>(
  ({ variant = "gray", isProminentVersion = false, hideForHandheld = false, children, className, ref, ...rest }) => {
    const classNames = [`variant--${variant}`, `hide-for-handheld--${hideForHandheld}`, `is-prominent-version--${isProminentVersion}`]
    if (className) {
      classNames.push(className)
    }
    return (
      <CalloutBarStyled className={classNames.join(" ")} {...rest}>
        {children}
      </CalloutBarStyled>
    )
  },
)
export const CalloutBarStyled = styled.div`
  background-color: ${palette.gray3};
  color: ${palette.gray1};
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(12)};
  padding: ${pxToRem(10)};
  line-height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: auto;
  text-align: center;

  &.variant--red {
    background-color: ${palette.red};
    color: ${palette.white};
  }
  &.variant--green {
    background-color: ${palette.green};
    color: ${palette.white};
  }
  &.variant--orange {
    background-color: ${palette.orange};
    color: ${palette.white};
  }
  &.variant--orange2 {
    background-color: ${palette.orange};
    color: ${palette.white};
  }
  &.variant--yellow {
    background-color: ${palette.yellow};
    color: ${palette.black};
  }
  &.variant--blue {
    background-color: ${palette.blue2};
    color: ${palette.white};
  }
  &.is-prominent-version--true {
    min-height: ${pxToRem(48)};
    font-size: ${pxToRem(14)};
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    &.hide-for-handheld--true {
      display: none;
    }
  }
`
