/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react"
import styled from "styled-components/macro"
import { CentralEntryFragment } from "../../__generated__/CentralEntryFragment"
import constants from "../../common/constants"
import { ENUM_AVAILABLE, ENUM_CHALLENGE, ENUM_MANAGER } from "../../common/enums"
import { toRank, unique, emptyArray } from "../../common/misc-utils"
import { TPoolRouteProps } from "../../routes.d"
import { A } from "../components/A"
import { ButtonSmallBase, ButtonWithAfterContentInteraction } from "../components/Button"
import CustomCopy from "../components/CustomCopy"
import Carrot from "../components/icons/Carrot"
import Checkmark from "../components/icons/Checkmark"
import Close from "../components/icons/Close"
import Link from "../components/Link"
import { Logo } from "../components/Logos"
import { breakpoints, fontWeight, palette, pxToRem } from "../utils/style-utils"
import { withoutDomain } from "../utils/url-utils"
import { MyTeamsRowInfo, MyTeamsRowLeague, MyTeamsRowName } from "./Layout/styles/SiteNavSide.styles"
import DlItem from "./PoolPages/components/DlItem"
import { getReactivatablePools } from "./NonPoolPages/components/OnboardingView"
import { CentralSegmentFragment } from "../../__generated__/CentralSegmentFragment"
import { VerticalOverflowContainer } from "../components/OverflowContainer"
import BadProdRoute from "../Base/BadProdRoute"
import { AllCentralCurrentUsersEntriesQuery } from "../../__generated__/AllCentralCurrentUsersEntriesQuery"
import { ALL_CENTRAL_CURRENT_USERS_ENTRIES_QUERY, ALL_CENTRAL_GAME_INSTANCES_QUERY } from "../utils/central-queries"
import LoadingView from "../components/LoadingView"
import { AllCentralGameInstancesQuery } from "../../__generated__/AllCentralGameInstancesQuery"
import { edgeToNode } from "../utils/misc-utils"
import { sortEntries } from "../utils/data-utils"
import { useQuery } from "@apollo/client"
import { CentralGameInstancesQuery_allSegments, CentralGameInstancesQuery_allSegments_season } from "../../__generated__/CentralGameInstancesQuery"

const isProdishEnv = constants.IS_PRODISH

const SectionHeader = styled.div`
  padding: 10px 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: ${fontWeight.semiBold};
`

const LinkTypeSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`

const colWidth = 420
const gapWidth = 12

const Styles = styled.div`
  padding: ${pxToRem(gapWidth)};
  padding: var(--grid-gap);
  margin: 0 auto;
  color: #13161a;
  column-width: ${pxToRem(colWidth)};
  column-gap: ${pxToRem(gapWidth)};
  column-gap: var(--grid-gap);
  width: 100%;
  max-width: ${pxToRem(colWidth * 1 + gapWidth * 3)};
  max-width: calc(${pxToRem(colWidth)} * 1 + var(--grid-gap) * 3);
  @media (min-width: ${pxToRem(colWidth * 2 + gapWidth * 3)}) {
    max-width: ${pxToRem(colWidth * 2 + gapWidth * 3)};
    max-width: calc(${pxToRem(colWidth)} * 2 + var(--grid-gap) * 3);
  }
  @media (min-width: ${pxToRem(colWidth * 3 + gapWidth * 4)}) {
    max-width: ${pxToRem(colWidth * 3 + gapWidth * 4)};
    max-width: calc(${pxToRem(colWidth)} * 3 + var(--grid-gap) * 4);
  }
  & a {
    cursor: pointer;
  }
  & .row-info {
    padding: 0;
  }
  & section {
    display: inline-block;
    overflow: hidden;
    width: ${pxToRem(colWidth)};
    max-width: 100%;
    margin-bottom: ${pxToRem(gapWidth)};
    margin-bottom: var(--grid-gap);
  }
  & section > * {
    background: #fff;
    border-radius: ${pxToRem(1)};
    border: 1px solid #dfe2e6;
  }
  & .h {
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    height: 54px;
    align-items: center;
    & svg,
    img {
      height: ${pxToRem(45)};
      width: ${pxToRem(45)};
      margin: 0 ${pxToRem(12)};
    }
  }

  & h2 {
    font-size: ${pxToRem(16)};
  }
  & .es {
    font-size: ${pxToRem(12)};
    flex: 1;
    box-shadow: inset 0 0 7px 0 #00000011;
    border: 1px solid #0000002b;
    border-left: none;
    border-right: none;
    max-height: ${pxToRem(450)};
  }
  & .e {
    display: flex;
    flex-flow: row nowrap;
    height: ${pxToRem(45)};
    align-items: center;
    padding: 0 ${pxToRem(16)};
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    justify-content: space-between;

    &:last-child {
      border: none;
    }
    & svg {
      display: block;
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
    }
    &[type="button"] {
      justify-content: center;
      width: 100%;
    }
  }

  & dl {
    flex: 0 1 3em;
    overflow: hidden;
    padding-right: 0.5em;
    text-align: center;
    color: #000;
    @media (max-width: ${pxToRem(breakpoints.handheld)}) {
      font-size: 0.6rem;
    }
  }
  & dt {
    font-weight: ${fontWeight.semiBold};
  }
  & dd {
    height: 1.4em;
  }
  & .bs {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    height: 54px;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 ${pxToRem(16)};
    & button {
      flex: 1;
      height: ${pxToRem(35)};
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: ${pxToRem(12)};
      border: none;
      outline: none;
      border-radius: 2px;
      width: 35%;
    }
  }
  & .eh {
    padding: 0 ${pxToRem(16)};
  }
`

const LinkItem = styled(A)`
  font-size: ${pxToRem(16)};
  margin: ${pxToRem(10)};
  padding: ${pxToRem(10)};
  min-width: ${pxToRem(220)};
  display: flex;
  justify-content: space-between;
  flex: 1;
`

interface IEntryRowProps {
  entry: CentralEntryFragment
  shouldReactivate: boolean
  shouldRollover: boolean
  rootSegment: CentralSegmentFragment
  entrySegment: CentralSegmentFragment
}

function EntryRow({ entry, shouldReactivate, shouldRollover, rootSegment, entrySegment }: IEntryRowProps) {
  const seasonContextText =
    (rootSegment.season.year !== entry.pool.season.year && entry.pool.season.year) ||
    (rootSegment.season.season !== entry.pool.season.season && entry.pool.season.season)
  const isRollable = !rootSegment.hasEnded && (shouldRollover || shouldReactivate)
  const classNames = ["e", `e__is-rollable--${isRollable}`, "is-light-bg--true"]
  return (
    <ButtonWithAfterContentInteraction as={A} to={withoutDomain(entry.url)} className={classNames.join(" ")} key={entry.id}>
      <MyTeamsRowInfo className="row-info">
        <MyTeamsRowName>{entry.name}</MyTeamsRowName>
        <MyTeamsRowLeague>{seasonContextText ? `(${seasonContextText}) ${entry.pool.name}` : entry.pool.name}</MyTeamsRowLeague>
      </MyTeamsRowInfo>
      {(isRollable && (
        <ButtonSmallBase as={"div"} className="e__cta">
          {(shouldRollover && `Rollover`) || `Reactivate`}
        </ButtonSmallBase>
      )) || (
        <>
          {(entry.pool.season.isCurrent && !entrySegment.hasEnded && (
            <DlItem dt={`Picks`} dd={entry.needsToMakePicks ? <Close color={palette.red} /> : <Checkmark color={palette.green} />} />
          )) ||
            null}
          <DlItem dt={`Pts`} dd={entry.fantasyPoints || 0} />
          <DlItem dt={`Rnk`} dd={toRank(entry.poolRank) || "--"} />
          <Carrot />
        </>
      )}
    </ButtonWithAfterContentInteraction>
  )
}

// const sortEntries = (a: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node, b: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node) => a.id

function Home(props: TPoolRouteProps) {
  // const { poolData } = props
  // const { allCurrentEntries, currentUser, allSegments, allGameInstances: gameInstances } = poolData
  const { data: allCurrentUsersEntries, loading: allCurrentUsersEntriesLoading } = useQuery<AllCentralCurrentUsersEntriesQuery>(
    ALL_CENTRAL_CURRENT_USERS_ENTRIES_QUERY,
  )
  const { data: allCentralGameInstances, loading: allCentralGameInstanceLoading } = useQuery<AllCentralGameInstancesQuery>(
    ALL_CENTRAL_GAME_INSTANCES_QUERY,
  )
  const [toggleKey, updateKey] = React.useState("")

  if (allCurrentUsersEntriesLoading || allCentralGameInstanceLoading || !allCurrentUsersEntries || !allCentralGameInstances) {
    return <LoadingView variant="view" />
  }

  if (["prod"].includes(constants.APP_ENV)) {
    return <BadProdRoute currentPathname={props.location.pathname} />
  }

  const allSegments = allCentralGameInstances.allSegments
  const segmentsOrdered = allSegments
    .filter(() => true)
    .sort((a, b) => {
      const aIsCurrent = a.season.isCurrent
      const ai =
        (a.season.currentSimulatedState?.id && 100000) ||
        (a.shouldPromoteToPlay && aIsCurrent && 10000) ||
        (!a.hasEnded && a.hasStarted && aIsCurrent && 1000) ||
        (!a.hasEnded && aIsCurrent && 100) ||
        (a.season.isCurrent && 10) ||
        0
      const bIsCurrent = b.season.isCurrent
      const bi =
        (b.season.currentSimulatedState?.id && 100000) ||
        (b.shouldPromoteToPlay && bIsCurrent && 10000) ||
        (!b.hasEnded && b.hasStarted && bIsCurrent && 1000) ||
        (!b.hasEnded && bIsCurrent && 100) ||
        (bIsCurrent && 10) ||
        0
      return bi - ai
    })

  // console.dir(segmentsOrdered)

  // const gameKeys = unique(segmentsOrdered.map(gameGroupKeyFor))
  const isCbsAdmin = allCurrentUsersEntries.currentUser.isCbsAdmin || !isProdishEnv
  let usedSeasonIds = [] as Array<string | number>

  const allCurrentEntries = sortEntries((allCurrentUsersEntries.currentUser.entries?.edges || emptyArray).map(edgeToNode))
  // NOTE qac: lets sort these so the first season is the most recent (as well as segments!)
  const gameInstanceEdges = (allCentralGameInstances.gameInstances.edges || emptyArray).map(edgeToNode)
  const gameUidsForArea = allSegments.map((seg) => seg.season.gameInstance.uid)

  const gameInstances = gameInstanceEdges.filter((gi) => gameUidsForArea.includes(gi.uid))

  const reactivatablePools = getReactivatablePools({
    allCurrentEntries,
    gameInstances,
    allSegments: allSegments as CentralGameInstancesQuery_allSegments[],
  })

  return (
    <Styles>
      {segmentsOrdered.map((rootSegment) => {
        if (usedSeasonIds.includes(rootSegment.season.id)) {
          return null
        }
        const segmentAndSister = segmentsOrdered.filter(
          (s) =>
            s.season.gameInstance.client === rootSegment.season.gameInstance.client &&
            s.season.year === rootSegment.season.year &&
            s.subsection === rootSegment.subsection &&
            s.sportType === rootSegment.sportType &&
            s.gameType === rootSegment.gameType &&
            s.season.season === rootSegment.season.season &&
            s.season.currentSimulatedState?.id === rootSegment.season.currentSimulatedState?.id &&
            !usedSeasonIds.includes(s.season.id),
        )
        const poolTypes = segmentAndSister.map((s) => s.season.gameInstance.poolType)
        const segmentsForSection = segmentsOrdered.filter(
          (s) =>
            s.season.gameInstance.client === rootSegment.season.gameInstance.client &&
            s.seasonType === rootSegment.seasonType &&
            s.sportType === rootSegment.sportType &&
            s.subsection === rootSegment.subsection &&
            s.gameType === rootSegment.gameType &&
            poolTypes.includes(s.season.gameInstance.poolType) &&
            s.season.currentSimulatedState?.id === rootSegment.season.currentSimulatedState?.id &&
            !usedSeasonIds.includes(s.season.id),
        )
        usedSeasonIds = usedSeasonIds.concat(segmentsForSection.map((seg) => seg.season.id))
        if (segmentsForSection.length) {
          const currentSegments = segmentsForSection.filter((s) => s.season.isCurrent)
          const currentChallengeSegments = currentSegments.filter((s) => s.season.gameInstance.poolType === ENUM_CHALLENGE)
          const currentManagerSegments = currentSegments.filter((s) => s.season.gameInstance.poolType === ENUM_MANAGER)
          const currentChallengeSegment = currentChallengeSegments?.[0]
          const currentManagerSegment = currentManagerSegments?.[0]
          const lastChallengeSegment = currentChallengeSegment || segmentsForSection.find((s) => s.season.gameInstance.poolType === ENUM_CHALLENGE)
          const segment = currentSegments?.[0] || segmentsForSection?.[0]
          const gameInstance = gameInstances.find((gi) => gi.id === segment.season.gameInstance.id)
          if (!gameInstance) {
            throw new Error(`arg`)
          }
          // const gameInstances = segmentsForSection.map((s) => s.season.gameInstance)
          // const poolTypes = unique(gameInstances.map((s) => s.poolType))
          // const hasManager = poolTypes.includes(ENUM_MANAGER)
          // const hasChallenge = poolTypes.includes(ENUM_CHALLENGE)
          // const gameInstanceUids = unique(segmentsForSection.map((s) => s.season.gameInstance.uid))
          const seasonIds = unique(segmentsForSection.map((s) => s.season.id))
          const entries = allCurrentEntries
            .filter((e) => seasonIds.includes(e.pool.season.id))
            .sort((a, b) => {
              const ai = seasonIds.indexOf(a.pool.season.id)
              const bi = seasonIds.indexOf(b.pool.season.id)
              if (ai !== bi) {
                return ai - bi
              }
              return 0
            })
          const archivedEntries = entries.filter((e) => !!e.pool.currentSeasonPoolId)
          const activeEntries = entries.filter((e) => !e.pool.currentSeasonPoolId)
          // const hasEntries = entries.length > 0
          const showManagerCta = !currentManagerSegment?.hasEnded
          const ctaSegments = currentSegments.filter((s) => s.shouldPromoteToPlay || s.season.currentSimulatedState?.id)
          // console.groupCollapsed(d)
          // console.groupCollapsed(unique(segmentsForSection.map((s) => s.season.gameInstance.uid)).join(","))
          // console.dir(segment)
          // console.dir(segmentsForSection)
          // console.groupEnd()
          // if manager only, and out of season, with no entries, dont show!
          if (!entries.length && !ctaSegments.length && !lastChallengeSegment) {
            return null
          }
          return (
            <section key={rootSegment.id}>
              <div>
                <div className="h">
                  <Logo gameInstanceUid={segment.season.gameInstance.uid} season={segment.season as CentralGameInstancesQuery_allSegments_season} />
                  <CustomCopy
                    as="h2"
                    segment={segment as CentralGameInstancesQuery_allSegments}
                    season={segment.season as CentralGameInstancesQuery_allSegments_season}
                    gameInstance={gameInstance}
                    code="titleWithClient"
                  />
                </div>
                {entries.length ? (
                  <VerticalOverflowContainer className={`es only-one--${activeEntries.length === 1 && !showManagerCta}`}>
                    {entries
                      .filter((entry) => toggleKey === rootSegment.id || !archivedEntries.includes(entry))
                      .map((entry) => {
                        const shouldReactivate = reactivatablePools.entriesToReactivate.includes(entry)
                        const shouldRollover = reactivatablePools.entriesToRollover.includes(entry)
                        const entrySegment = segmentsForSection.find((s) => s.season.id === entry.pool.season.id)
                        if (!entrySegment) {
                          return null
                        }

                        return (
                          <EntryRow
                            entry={entry}
                            key={entry.id}
                            shouldRollover={shouldRollover}
                            shouldReactivate={shouldReactivate}
                            rootSegment={rootSegment as CentralGameInstancesQuery_allSegments}
                            entrySegment={entrySegment as CentralGameInstancesQuery_allSegments}
                          />
                        )
                      })}
                    {(archivedEntries.length && toggleKey !== rootSegment.id && (
                      <ButtonWithAfterContentInteraction
                        type="button"
                        className="e e__show-archived-btn is-light-bg--true"
                        onClick={() => updateKey(rootSegment.id)}
                      >
                        Show Archived
                      </ButtonWithAfterContentInteraction>
                    )) ||
                      null}
                  </VerticalOverflowContainer>
                ) : null}
                {(ctaSegments.length > 0 && (
                  <div className="bs">
                    {ctaSegments.map((seg) => {
                      const areaHasMultipleEntriesPerUser =
                        seg.poolSettings.__typename === "PoolSettings" && seg.poolSettings?.multipleEntriesOption === ENUM_AVAILABLE
                      const isChallenge = seg.season.gameInstance.poolType === ENUM_CHALLENGE
                      const isLobbyLink = isChallenge && areaHasMultipleEntriesPerUser
                      const challEntry = (isChallenge && entries.find((e) => e.pool.season.id === seg.season.id)) || null
                      const isLeaderboardLink = !isLobbyLink && challEntry && !challEntry.needsToMakePicks
                      const url = withoutDomain((isLobbyLink && seg.baseUrl) || seg.landingUrl + (isLeaderboardLink ? "/standings" : ""))
                      return (
                        <ButtonSmallBase key={seg.id} as={Link} to={url}>
                          {(isLobbyLink && `Lobby`) ||
                            (isLeaderboardLink && `View Leaderboard`) ||
                            (challEntry && `Make Your Picks`) ||
                            (isChallenge && `Play Now`) ||
                            `Start Pool`}
                        </ButtonSmallBase>
                      )
                    })}
                  </div>
                )) ||
                  (!!lastChallengeSegment && (
                    <div className="bs">
                      <ButtonSmallBase as={Link} to={withoutDomain(lastChallengeSegment.landingUrl + "/standings")}>
                        {`View ${lastChallengeSegment.season.year} Challenge Results`}
                      </ButtonSmallBase>
                    </div>
                  )) || <div className="bs">Not in Season</div>}
              </div>
            </section>
          )
        } else {
          return null
        }
      })}
      {(isCbsAdmin && (
        <section>
          <SectionHeader className="h eh">Debug</SectionHeader>
          <LinkTypeSection>
            <LinkItem to="/scratchpad">
              <div>Scratchpad</div>
              <Carrot />
            </LinkItem>
          </LinkTypeSection>
          <LinkTypeSection>
            <LinkItem to="/admin/game-instances">
              <div>Admin</div>
              <Carrot />
            </LinkItem>
          </LinkTypeSection>
          <LinkTypeSection>
            <LinkItem to="/graphql" external={true} href="/graphql">
              <div>GraphQL Playground</div>
              <Carrot />
            </LinkItem>
          </LinkTypeSection>
        </section>
      )) ||
        null}
    </Styles>
  )
}

export default Home
