import * as React from "react"

const Svg = ({ color = "currentColor", className = "" }) => (
  <svg className={`information-outline-svg ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    {/* tslint:disable-next-line */}
    <path
      fill={color}
      d="M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"
    />
    <path fill={color} d="M277 360h-42V235h42v125zm0-166h-42v-42h42v42z" />
  </svg>
)

export default Svg
